var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseSection', {
    attrs: {
      "id": _vm.section.key,
      "is-visible": _vm.isSectionVisible,
      "is-modified": _vm.isDataChanged,
      "section": _vm.section
    },
    on: {
      "onSectionSaveClick": _vm.save
    },
    scopedSlots: _vm._u([{
      key: "section",
      fn: function fn() {
        return [_c('v-card-text', {
          staticClass: "mt-4"
        }, [_c('div', {
          staticClass: "mb-5"
        }, [_c('v-expand-transition', [_c('v-textarea', {
          attrs: {
            "outlined": "",
            "name": "input-7-4",
            "label": "Purpose",
            "placeholder": "Purpose",
            "rows": "8",
            "auto-grow": "",
            "disabled": _vm.FIELDS_DISABLED
          },
          model: {
            value: _vm.lendingNotes.purpose,
            callback: function callback($$v) {
              _vm.$set(_vm.lendingNotes, "purpose", $$v);
            },
            expression: "lendingNotes.purpose"
          }
        })], 1), _c('v-expand-transition', [_c('v-textarea', {
          attrs: {
            "outlined": "",
            "name": "input-7-4",
            "label": "Client Background and Character",
            "placeholder": "Client Background and Character",
            "rows": "20",
            "auto-grow": "",
            "disabled": _vm.FIELDS_DISABLED
          },
          model: {
            value: _vm.lendingNotes.backgrounds,
            callback: function callback($$v) {
              _vm.$set(_vm.lendingNotes, "backgrounds", $$v);
            },
            expression: "lendingNotes.backgrounds"
          }
        })], 1), _c('v-expand-transition', [_c('v-textarea', {
          attrs: {
            "outlined": "",
            "name": "input-7-4",
            "label": "Other notes",
            "placeholder": "Other notes",
            "rows": "8",
            "auto-grow": "",
            "disabled": _vm.FIELDS_DISABLED
          },
          model: {
            value: _vm.lendingNotes.other,
            callback: function callback($$v) {
              _vm.$set(_vm.lendingNotes, "other", $$v);
            },
            expression: "lendingNotes.other"
          }
        })], 1)], 1)])];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }